import React from 'react';
import ReactDOM from "react-dom/client";
import App, {route, group} from 'library/App';
import ServiceWorker from 'library/ServiceWorker';

// Platform home
route('/@platform/views/Home/HomeView.js');

// Checkout
route('/documents/:documents_uuid/checkout.html@platform/views/Checkout/CheckoutView.js', 'orders@get_checkout_info');

// Summary
route('/documents/:documents_uuid/summary.html@platform/views/Summary/SummaryView.js');

// Identify
route('/identification/verify.html@platform/views/Identify/IdentifyView.js');

// Share
route('/documents/:documents_uuid/share.html@platform/views/Share/ShareView.js', 'documents@get_document');

// Login
route('/system/login.html@system/views/Login/LoginView.js');

group('ADMIN', () => {

    // System home
    route('/system/home.html@system/views/Home/HomeView.js');

    // Organizations list
    route('/system/organizations/list.html@system/views/OrganizationsList/OrganizationsListView.js', 'organizations@get_organizations', [

        route('/system/organizations/:organizations_uuid/edit.html@system/views/OrganizationsEdit/OrganizationsEditView.js')
    ]);

    // Accounts list
    route('/system/accounts/list.html@system/views/AccountsList/AccountsListView.js', 'accounts@get_accounts', [

            // Accounts add
            route('/system/accounts/add.html@system/views/AccountsAdd/AccountsAddView.js'),

            // Accounts update
            route('/system/accounts/:accounts_uuid/update.html@system/views/AccountsUpdate/AccountsUpdateView.js', 'account@get_account'),

            // Privileges list
            route('/system/accounts/:accounts_uuid/privileges/list.html@system/views/PrivilegesList/PrivilegesListView.js', 'privileges@get_account_privileges', [

                // Privileges add
                route('/system/accounts/:accounts_uuid/privileges/add.html@system/views/PrivilegesAdd/PrivilegesAddView.js')
            ])
        ]
    );

    // Documents list
    route('/system/documents/list.html@system/views/DocumentsList/DocumentsListView.js', 'documents@get_documents');

    // Feedback list
    route('/system/feedback/list.html@system/views/FeedbackList/FeedbackListView.js', 'feedback@get_feedback');
});

ReactDOM.createRoot(document.getElementById("root")).render(

    <App />
);

ServiceWorker.register();
