import {Preloading, Alert, Confirm} from 'library/components';
import Loading from 'components/Loading';

const config = {

    defaultLang: 'fi',
    defaultMode: 'light',
    defaultTheme: 'system',

    redirect: {

        forbidden: path => {

            if(path.match(/\/system\//i) !== null){

                return '/system/login.html';
            }

            return '/';
        }
    },

    components: {

        loading: Loading,
        preloading: Preloading,
        alert: Alert,
        confirm: Confirm
    },

    endpoints: {

        refreshAccessToken: {

            endpoint: '/api/auth/refresh_access_token/',
            method: 'get'
        }
    }
};

export default config;
